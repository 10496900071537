import {
  Banner,
  Helmet,
  Layout,
  Container,
  Theme,
  routesObject,
  ArticleCardsSection,
  Section,
  RelatedPageTiles
} from "@life-without-barriers/react-components"
import React from "react"
import { Site, contentTypes as ct } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"
import { helpers, pageUrls } from "@life-without-barriers/utilities"

import { IGatsbyImageData } from "gatsby-plugin-image"
import NewsFeaturedArticlesPanel from "../../components/news/NewsFeaturedPanel"

const { corporateThemeFull } = Theme
const { truncate } = helpers
const { generateNewsIndexUrl } = pageUrls

interface Props {
  location: { pathname: string }
  pageContext: {
    nextPage: number
    pageNumber: number
    category: string
    allCategories: string[]
  }
  data: {
    site: Site
    allContentfulNewsArticle: ct.ContentNodes<ct.NewsArticleSummary>
    allContentfulNewsFeaturedPanel: ct.ContentNodes<ct.NewsFeaturedPanel>
    openGraphImage: IGatsbyImageData
  }
}

const IndexTemplate = ({
  location,
  pageContext: { nextPage, category, allCategories },
  data: {
    site: { siteMetadata },
    allContentfulNewsArticle,
    allContentfulNewsFeaturedPanel,
    openGraphImage
  }
}: Props) => {
  const articles = allContentfulNewsArticle.edges
    .filter(({ node }) => node.featureImage)
    .map(({ node }) => ({
      ...node,
      href: `/news/${node.slug}`,
      title: truncate(node.title, 65),
      copy: truncate(node.introductionText.text, 110),
      image: node.featureImage.gatsbyImageData
    }))

  const newAllCategories = allCategories.map((category) => {
    if (category === "Aboriginal and Torres Strait Islander people") {
      return "Aboriginal and Torres Strait Islander People"
    } else if (category === "Child, youth and family") {
      return "Child, Youth and Family"
    } else if (category === "Refugees and asylum seekers") {
      return "Refugees and Asylum Seekers"
    } else {
      return category
    }
  })

  return (
    <div>
      <Helmet
        title={`Latest news | ${siteMetadata.title}`}
        description="We are proud to share our latest news with you our valued supporters"
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={openGraphImage}
      />
      <Layout theme={corporateThemeFull}>
        <Banner
          title="News"
          subTitle="We are proud to share our latest news with you our valued supporters."
          breadcrumbs={[routesObject.home]}
        />

        <div className="ph3 ph4-ns bg-lwb-white">
          <Container>
            <NewsFeaturedArticlesPanel
              title="Highlights"
              articles={allContentfulNewsFeaturedPanel}
            />
            <ArticleCardsSection
              nextPage={nextPage}
              allCategories={newAllCategories}
              selectedCategory={category}
              articles={articles}
              generateIndexUrl={generateNewsIndexUrl}
              heading={"LATEST NEWS"}
            />
          </Container>
        </div>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <RelatedPageTiles
              title="Other pages you might like"
              paragraph="Read about what's happening in our organisation and how we strive to deliver excellent services, break barriers to inclusion and change lives for the better."
              pages={[
                {
                  title: "Media and Public Affairs",
                  description: "Latest Media from Life Without Barriers.",
                  path: "/media"
                },
                {
                  title: "Social Policy",
                  description:
                    "Learn more about social policy at Life Without Barriers.",
                  path: "/social-policy"
                }
              ]}
            />
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  # reusable fragment
  fragment ArticleFields on ContentfulNewsArticle {
    contentful_id
    __typename
    id
    slug
    title
    category
    introductionText {
      text: introductionText
    }
    date: date(formatString: "D MMM YYYY")
    featureImage {
      file {
        url
        fileName
        contentType
      }
      title
      description
      gatsbyImageData(width: 1200)
    }
  }

  # main query
  query ($pageSize: Int, $categories: [String]) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    openGraphImage: file(relativePath: { regex: "/opengraph.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    allContentfulNewsArticle(
      filter: {
        category: { in: $categories }
        publishingOptions: { nin: "Hide on index" }
      }
      limit: $pageSize
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          ...NewsArticleSummary
        }
      }
    }
    allContentfulNewsFeaturedPanel(
      limit: 1
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          heroArticle {
            ...ArticleFields
          }
          alternativeHeroArticleImage {
            ...RemoteContentfulAsset
          }
          featuredArticle1 {
            ...ArticleFields
          }
          featuredArticle2 {
            ...ArticleFields
          }
          featuredArticle3 {
            ...ArticleFields
          }
        }
      }
    }
  }
`

export default IndexTemplate
