import React from "react"
import styled from "styled-components"
import {
  NewsHeroArticle,
  NewsFeaturedArticle,
  media,
  Heading
} from "@life-without-barriers/react-components"
import { contentTypes as ct } from "@life-without-barriers/gatsby-common"
import { contentfulHelpers as Contentful } from "@life-without-barriers/utilities"

interface Props {
  articles: ct.ContentNodes<ct.NewsFeaturedPanel>
  title: string
  className?: string
}

const formatArticleNode = (
  node: ct.NewsArticle,
  alternateImage?: Contentful.RemoteImageAsset
) => {
  return {
    ...node,
    href: `/news/${node.slug}`,
    title: node.title,
    copy: node.introductionText.text,
    image: {
      childImageSharp: alternateImage
        ? alternateImage.gatsbyImageData
        : node.featureImage.gatsbyImageData
    }
  }
}

/**
 * Abstract the Hero and featured articles, passing them
 * off to relevant functions
 */
const NewsFeaturedPanel = ({ title, className, articles }: Props) => {
  const alternativeHeroArticleImage =
    articles.edges[0].node.alternativeHeroArticleImage
  const heroArticle = formatArticleNode(
    articles.edges[0].node.heroArticle,
    alternativeHeroArticleImage
  )

  const featuredArticle1 = formatArticleNode(
    articles.edges[0].node.featuredArticle1
  )
  const featuredArticle2 = formatArticleNode(
    articles.edges[0].node.featuredArticle2
  )
  const featuredArticle3 = formatArticleNode(
    articles.edges[0].node.featuredArticle3
  )

  return (
    <StyledPanel className={className}>
      <Heading size={2} sectionHeading>
        {title}
      </Heading>
      <div className="flex-l mt4-ns mt3">
        <NewsHeroArticle {...heroArticle} />
        <StyledFeaturedArticles className="w-50-l relative">
          <NewsFeaturedArticle {...featuredArticle1} />
          <NewsFeaturedArticle {...featuredArticle2} />
          <NewsFeaturedArticle {...featuredArticle3} />
        </StyledFeaturedArticles>
      </div>
    </StyledPanel>
  )
}

const StyledPanel = styled.div`
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #eee;

  ${media.notSmall`
      padding-bottom: 2rem;
      margin-bottom: 2rem;
    `}
`

const StyledFeaturedArticles = styled.div`
  .featured-article:first-child {
    padding-top: 0;
  }

  .featured-article:last-child {
    border-bottom: none;
  }

  .featured-article {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
  }
`

export default NewsFeaturedPanel
